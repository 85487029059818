<template>
  <div>
    <div ref="canvas" class="perfil-sondagem"></div>
  </div>
</template>

<script>
import { SVG, Point } from "@svgdotjs/svg.js";
import perfilSondagemSvg from "./../Componentes/sondagemSVG";
import Meta from "./../../shared/meta-dados";
export default {
  name: "PerfilSondagem",
  components: {},
  data() {
    return {
      desenho: "",
      larguraPerfil: this.largura - 0.1 * this.largura,
      corSondagem: { color: "#7cb5ec", width: 2 },
      alturaSVG: 0,
      escala: 1,
      grupoVizualizacao: false
    };
  },
  props: {
    sondagemSPT: {
      type: Array,
      default: () => {
        return [];
      }
    },
    largura: {
      type: Number,
      default: 320
    },
    altura: {
      type: Number,
      required: true
    },
    nivelAgua: {
      type: Number,
      default: 0
    },
    unidade: {
      type: String,
      default: ""
    },
    prefixo: {
      type: String,
      default: "NSPT"
    }
  },
  methods: {
    gerarPerfil(altura = 0) {
      if (altura > 0) this.alturaSVG = altura;
      this.desenho.clear();
      let alturaPerfil = this.calcularAlturaPerfil();
      let perfil = perfilSondagemSvg(
        this.desenho,
        this.sondagemSPT,
        this.larguraPerfil,
        alturaPerfil,
        null
      ).transform({
        a: 1,
        b: 0,
        c: 0,
        d: 1,
        e: this.larguraPerfil * 0.05,
        f: 0
      });
      this.grupoVizualizacao = false;
      let grupoAgua = this.gerarNivelAgua();
      let grupoLegendaCamada = this.gerarLegendaCamadas();
      let linhaSondagem = this.gerarLinhaSondagem();
      let grupoNomeDosSolos = this.gerarLegendaSolos();
      this.especificarViewBox(alturaPerfil);

      this.desenho.on("mousemove", this.gerarEixosVisualizacao);
    },
    gerarLegendaCamadas() {
      let grupoLegendaCamada = this.desenho.group();
      let larguraCaixaAgua = (this.largura * 0.1) / 2;
      let numeroCamada =
        this.sondagemSPT.length == 0 ? 1 : this.sondagemSPT.length;
      let alturaPerfil = this.calcularAlturaPerfil();
      for (let i = 1; i <= numeroCamada; i++) {
        let alturaLegenda = i * alturaPerfil - alturaPerfil / 2;
        let texto = grupoLegendaCamada.text(i.toString());
        texto.move(larguraCaixaAgua, alturaLegenda - 15).attr({
          fill: "#606062",
          "font-size": 15,
          "text-anchor": "middle",
          "font-weight": "bold"
        });
      }
      grupoLegendaCamada.transform({
        a: 1,
        b: 0,
        c: 0,
        d: 1,
        e: -this.larguraPerfil / 2 - this.larguraPerfil * 0.05,
        f: 0
      });
    },
    gerarNivelAgua() {
      let grupoAgua = this.desenho.group();
      let numeroCamada =
        this.sondagemSPT.length == 0 ? 1 : this.sondagemSPT.length;
      let alturaPerfil = this.calcularAlturaPerfil();
      let alturaCaixa = alturaPerfil * numeroCamada;
      let larguraCaixaAgua = this.largura * 0.1;
      let caixaNivelAgua = grupoAgua
        .rect(larguraCaixaAgua, alturaCaixa)
        .fill("#fff");
      if (numeroCamada > this.nivelAgua && this.nivelAgua > 0) {
        let caixaAgua = grupoAgua
          .rect(
            larguraCaixaAgua,
            (numeroCamada - this.nivelAgua) * alturaPerfil
          )
          .fill("#A8E2F7")
          .move(0, this.nivelAgua * alturaPerfil);
      }
      for (let i = 1; i <= numeroCamada; i++) {
        let alturaCamadaAgua = i * alturaPerfil;
        grupoAgua
          .line([0, alturaCamadaAgua, larguraCaixaAgua, alturaCamadaAgua])
          .fill("none")
          .stroke("#E6E7E8");
      }
      grupoAgua.transform({
        a: 1,
        b: 0,
        c: 0,
        d: 1,
        e: -this.larguraPerfil / 2 - this.larguraPerfil * 0.05,
        f: 0
      });
      return grupoAgua;
    },
    gerarLinhaSondagem() {
      let grupoLinha = this.desenho.group();
      let alturaPerfil = this.calcularAlturaPerfil();
      if (
        this.sondagemSPT.length == undefined ||
        this.sondagemSPT.length == 0 ||
        this.sondagemSPT[0].length == 0
      )
        return;
      let nsptMaximo = 0;

      let pontos = [[0, 0]];
      this.sondagemSPT.forEach(element => {
        nsptMaximo = Math.max(element.nspt, nsptMaximo);
        pontos.push([element.nspt, element.camada * alturaPerfil]);
      });
      let linhaSondagem = grupoLinha
        .polyline([0, 0])
        .fill("none")
        .stroke(this.corSondagem)
        .attr({ "vector-effect": "non-scaling-stroke" });
      let escala = this.larguraPerfil / nsptMaximo;
      this.escala = escala;
      linhaSondagem.transform({
        a: escala,
        b: 0,
        c: 0,
        d: 1,
        e: -this.larguraPerfil / 2 + this.larguraPerfil * 0.05,
        f: 0
      });
      this.gerarPontosDados(pontos, grupoLinha, escala);
      linhaSondagem.plot(pontos);
      return grupoLinha;
    },
    gerarPontosDados(pontos, grupo, escala) {
      let texto = grupo.text("");

      if (pontos.length <= 1) {
        return;
      }
      for (let i = 1; i < pontos.length; i++) {
        let x =
          pontos[i][0] * escala -
          this.larguraPerfil / 2 +
          this.larguraPerfil * 0.05 -
          4;
        let y = pontos[i][1] - 4;
        let xTexto = x > this.larguraPerfil / 2 - 60 ? x - 80 : x;

        grupo
          .circle(8)
          .move(x, y)
          .fill(this.corSondagem)
          .stroke("none");
      }
    },
    especificarViewBox(alturaPerfil) {
      let alturaTotal = this.sondagemSPT.length * alturaPerfil;
      this.desenho.viewbox(
        -this.largura / 2 - 5,
        -5,
        this.largura + 10,
        alturaTotal + 10
      );
    },
    calcularAlturaPerfil() {
      let alturaMinima = window.innerHeight * 0.7;
      let numeroCamada =
        this.sondagemSPT.length == 0 ? 1 : this.sondagemSPT.length;
      let altura = alturaMinima / numeroCamada;

      return altura;
    },
    gerarEixosVisualizacao(event) {
      if (
        this.sondagemSPT.length == undefined ||
        this.sondagemSPT.length == 0 ||
        this.sondagemSPT[0].length == 0
      )
        return;
      event.preventDefault();

      let alturaPerfil = this.calcularAlturaPerfil();
      let y = event.clientY;
      let x = event.clientX;
      let point = new Point(x, y).transform(this.desenho.screenCTM().inverse());
      let yTexto = Math.round(point.y / alturaPerfil);

      let grupo = this.grupoVizualizacao
        ? this.grupoVizualizacao
        : this.iniciarEixoVizualizacao();

      let texto = grupo.first();
      let camada = yTexto == 0 ? yTexto : yTexto - 1;
      let valor =
        -this.largura / 2 + this.sondagemSPT[camada].nspt * this.escala;
      let textoLegenda =
        this.prefixo +
        ": " +
        this.sondagemSPT[camada].nspt.toString() +
        this.unidade;

      let variacaoX =
        this.sondagemSPT[camada].nspt * this.escala > this.largura / 2
          ? -40
          : 40;
      texto
        .animate(1000, 0, "now")
        .move(valor + variacaoX, yTexto * alturaPerfil - 25);
      texto.text(textoLegenda);
    },
    iniciarEixoVizualizacao() {
      this.grupoVizualizacao = this.desenho.group();
      this.grupoVizualizacao
        .text("")
        .move(0, 0)
        .attr({ fill: "#000", "font-size": 15, "font-weight": "bold" });
      return this.grupoVizualizacao;
    },
    gerarLegendaSolos() {
      if (
        this.sondagemSPT.length == undefined ||
        this.sondagemSPT.length == 0 ||
        this.sondagemSPT[0].length == 0
      )
        return;
      let grupoSolos = this.desenho.group();
      let numeroCamada = this.sondagemSPT.length;
      let alturaPerfil = this.calcularAlturaPerfil();

      for (let i = 0; i < numeroCamada; i++) {
        let sptEscala = this.sondagemSPT[i]["nspt"] * this.escala;
        let posicaoX =
          sptEscala < this.larguraPerfil / 2
            ? this.largura / 2 - 20
            : -this.largura / 2 + this.largura * 0.1 + 20;
        let posicaoY = alturaPerfil * i + alturaPerfil / 2 - 15;
        let atributosTexto = {
          fill: "#606062",
          "font-size": 15,
          "text-anchor": posicaoX > 0 ? "end" : "start",
          "font-weight": "bold"
        };
        let textoSolo = Meta.obtenhaSolos[this.sondagemSPT[i].solo].toString();
        grupoSolos
          .text(textoSolo)
          .move(posicaoX, posicaoY)
          .attr(atributosTexto);
      }
    }
  },

  mounted() {
    let altura = Math.max(window.innerHeight * 0.7);
    this.desenho = SVG()
      .addTo(this.$refs.canvas)
      .viewbox(-this.largura / 2, 0, this.largura, altura)
      .on("selectstart", function(event) {
        event.preventDefault();
      });
    this.gerarPerfil();
  },
  watch: {
    sondagemSPT(novoValor, velhoValor) {
      this.gerarPerfil();
    },
    nivelAgua(novoValor, velhoValor) {
      this.gerarPerfil();
    },
    altura(novoValor, velhoValor) {
      this.alturaSVG = this.altura;
    }
  },
  created: function() {
    this.alutraSVG = this.altura;
    this.$eventoHub.$on("atualizar-altura", this.gerarPerfil);
  },
  destroyed: function() {
    this.$eventoHub.$off("atualizar-altura", this.gerarPerfil);
  }
};
</script>

<style scoped>
svg g text {
  cursor: default;
}
</style>

import { SVG } from "@svgdotjs/svg.js";
let pontosPatternAreia = [
    {
        "x": "0.4781",
        "y": "3.7433"
    },
    {
        "x": "0.4885",
        "y": "0.457"
    },
    {
        "x": "0.5052",
        "y": "2.0947"
    },
    {
        "x": "0.5711",
        "y": "5.126"
    },
    {
        "x": "0.7065",
        "y": "1.8235"
    },
    {
        "x": "0.7875",
        "y": "4.2914"
    },
    {
        "x": "1.2374",
        "y": "2.5628"
    },
    {
        "x": "1.2515",
        "y": "0.5392"
    },
    {
        "x": "1.2656",
        "y": "3.7012"
    },
    {
        "x": "1.4069",
        "y": "1.7885"
    },
    {
        "x": "1.4624",
        "y": "3.912"
    },
    {
        "x": "1.6733",
        "y": "0.9889"
    },
    {
        "x": "1.7155",
        "y": "5.247"
    },
    {
        "x": "1.7999",
        "y": "3.4904"
    },
    {
        "x": "2.2454",
        "y": "1.8413"
    },
    {
        "x": "2.3173",
        "y": "0.5899"
    },
    {
        "x": "2.3828",
        "y": "3.0493"
    },
    {
        "x": "2.3905",
        "y": "4.9379"
    },
    {
        "x": "2.4399",
        "y": "0.2399"
    },
    {
        "x": "2.4889",
        "y": "5.5"
    },
    {
        "x": "2.5592",
        "y": "5.0081"
    },
    {
        "x": "2.5733",
        "y": "4.1509"
    },
    {
        "x": "2.7025",
        "y": "0.4412"
    },
    {
        "x": "2.8773",
        "y": "1.759"
    },
    {
        "x": "2.8863",
        "y": "0.2049"
    },
    {
        "x": "3.1498",
        "y": "3.6731"
    },
    {
        "x": "3.3326",
        "y": "4.9941"
    },
    {
        "x": "3.4028",
        "y": "0.5812"
    },
    {
        "x": "3.6015",
        "y": "2.7015"
    },
    {
        "x": "3.7563",
        "y": "1.4982"
    },
    {
        "x": "3.9232",
        "y": "5.3876"
    },
    {
        "x": "3.9423",
        "y": "1.6164"
    },
    {
        "x": "3.9477",
        "y": "1.8488"
    },
    {
        "x": "3.9935",
        "y": "4.2774"
    },
    {
        "x": "4.0216",
        "y": "4.9238"
    },
    {
        "x": "4.4069",
        "y": "0.1868"
    },
    {
        "x": "4.4576",
        "y": "3.7574"
    },
    {
        "x": "4.5138",
        "y": "5.0643"
    },
    {
        "x": "4.7388",
        "y": "0.8483"
    },
    {
        "x": "4.781",
        "y": "4.4741"
    },
    {
        "x": "5.0454",
        "y": "2.6217"
    },
    {
        "x": "5.0763",
        "y": "4.7692"
    },
    {
        "x": "5.0903",
        "y": "3.6871"
    },
    {
        "x": "5.223",
        "y": "0.3318"
    },
    {
        "x": "5.278",
        "y": "2.8432"
    }
];

let corPrimeiroPlano = "#D2D3D5";

function patternArgila(largura, altura, svg, color) {

    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura, largura, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternSilte(largura, altura, svg, color) {
    let fatorA = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        add.line(0, fatorA, largura / 4, fatorA).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorA, largura, fatorA).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorA) / 2, largura - largura / 4, (altura - 2 * fatorA) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorA) / 2, largura - largura / 4, (altura + 2 * fatorA) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorA, largura / 4, altura - fatorA).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorA, largura, altura - fatorA).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternAreia(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });

    });
    return pattern;
}

function patternAreiaArgilosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

        //areia

        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });

    });
    return pattern;
}

function patternAreiaArgiloSiltosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let fatorAS = altura / 40;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
    });
    return pattern;
}

function patternAreiaSiltoArgilosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let fatorAS = altura / 20;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
    });
    return pattern;
}

function patternAreiaSiltosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let fatorAS = altura / 20;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });

        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
    });
    return pattern;
}

function patternArgilaArenosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura, largura, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

    });
    return pattern;
}

function patternArgilaArenoSiltosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let fatorAS = altura / 40;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });

        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
        //Argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura, largura, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternArgilaSiltoArenosa(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura, largura, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternArgilaSiltosa(largura, altura, svg, color) {

    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura, largura, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternSilteArenoArgiloso(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });

        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternSilteArenoso(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);

        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });

        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternSilteArgiloArenoso(largura, altura, svg, color) {
    let fatorA = altura / 5.5;
    let fatorL = largura / 5.5;
    let raio = 0.0025;
    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);
        //areia
        pontosPatternAreia.forEach(element => {
            add.circle(raio).move(element.x * fatorL, element.y * fatorA).stroke("none").fill("#f06");
        });

        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}

function patternSilteArgiloso(largura, altura, svg, color) {
    let fatorAS = altura / 20;
    let pattern = svg.pattern(largura, altura, (add) => {
        add.rect(largura, altura).fill(color);

        //argila
        add.line(0, altura / 2, largura / 2, 0).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 2, altura, largura, altura / 2).stroke({ color: corPrimeiroPlano, width: 1 });

        //silte
        add.line(0, fatorAS, largura / 4, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, fatorAS, largura, fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura - 2 * fatorAS) / 2, largura - largura / 4, (altura - 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura / 4, (altura + 2 * fatorAS) / 2, largura - largura / 4, (altura + 2 * fatorAS) / 2).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(0, altura - fatorAS, largura / 4, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
        add.line(largura - largura / 4, altura - fatorAS, largura, altura - fatorAS).stroke({ color: corPrimeiroPlano, width: 1 });
    });
    return pattern;
}
export {
    patternAreia,
    patternArgila,
    patternSilte,
    patternAreiaArgilosa,
    patternAreiaArgiloSiltosa,
    patternAreiaSiltoArgilosa,
    patternAreiaSiltosa,
    patternArgilaArenosa,
    patternArgilaArenoSiltosa,
    patternArgilaSiltoArenosa,
    patternArgilaSiltosa,
    patternSilteArenoArgiloso,
    patternSilteArenoso,
    patternSilteArgiloArenoso,
    patternSilteArgiloso
}
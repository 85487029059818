import { render, staticRenderFns } from "./PerfilSondagem.vue?vue&type=template&id=4428743c&scoped=true&"
import script from "./PerfilSondagem.vue?vue&type=script&lang=js&"
export * from "./PerfilSondagem.vue?vue&type=script&lang=js&"
import style0 from "./PerfilSondagem.vue?vue&type=style&index=0&id=4428743c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4428743c",
  null
  
)

export default component.exports
import {
    patternAreia,
    patternArgila,
    patternSilte,
    patternAreiaArgilosa,
    patternAreiaArgiloSiltosa,
    patternAreiaSiltoArgilosa,
    patternAreiaSiltosa,
    patternArgilaArenosa,
    patternArgilaArenoSiltosa,
    patternArgilaSiltoArenosa,
    patternArgilaSiltosa,
    patternSilteArenoArgiloso,
    patternSilteArenoso,
    patternSilteArgiloArenoso,
    patternSilteArgiloso
} from "@/views/Componentes/patterns";
import { Color } from "@svgdotjs/svg.js";
let colorAreia = new Color("#FFF").to("#FFF68A");
let colorArgila = new Color("#FFF").to("#AD5D4A");
let colorSilte = new Color("#FFF").to("#C6BE76");

function perfilSondagemSvg(svg, spt, larguraTela, alturaCamada, indeslocavel = null) {
    let grupo = svg.group();

    let numeroCamadas = Math.max(spt.length, indeslocavel);
    let soloUltimaCamada = spt[spt.length - 1];
    for (let i = 0; i < numeroCamadas; i++) {
        let camada = grupo.rect(larguraTela, alturaCamada).move(-larguraTela / 2, i * alturaCamada).stroke('#E6E7E8');
        let hachura = (i >= spt.length) ? gerarHachura(grupo, soloUltimaCamada.solo, soloUltimaCamada.nspt) : gerarHachura(grupo, spt[i].solo, spt[i].nspt);
        camada.fill(hachura);
    }
    if (indeslocavel !== null) {
        grupo.rect(larguraTela, alturaCamada / 4).move(-larguraTela / 2, indeslocavel * alturaCamada).stroke('#E6E7E8').fill("#84716B");
        let texto = grupo.text("Solo Indeformável");
        texto
            .move(0, indeslocavel * alturaCamada + (alturaCamada / 8 - 15) / 2)
            .font({ fill: "#fff", "font-size": 15, "text-anchor": "middle" });
    }
    return grupo;

}
function gerarHachura(svg, tipoSolo, nspt) {
    switch (tipoSolo) {
        case 1:
            return patternAreia(20, 20, svg, colorAreia.at(nspt / 50));
        case 2:
            return patternAreiaArgilosa(20, 20, svg, colorAreia.at(nspt / 50));
        case 3:
            return patternAreiaArgiloSiltosa(20, 20, svg, colorAreia.at(nspt / 50));
        case 4:
            return patternAreiaSiltoArgilosa(20, 20, svg, colorAreia.at(nspt / 50));
        case 5:
            return patternAreiaSiltosa(20, 20, svg, colorAreia.at(nspt / 50));
        case 6:
            return patternArgila(20, 20, svg, colorArgila.at(nspt / 50));
        case 7:
            return patternArgilaArenosa(20, 20, svg, colorArgila.at(nspt / 50));
        case 8:
            return patternArgilaArenoSiltosa(20, 20, svg, colorArgila.at(nspt / 50));
        case 9:
            return patternArgilaSiltoArenosa(20, 20, svg, colorArgila.at(nspt / 50));
        case 10:
            return patternArgilaSiltosa(20, 20, svg, colorArgila.at(nspt / 50));
        case 11:
            return patternSilte(20, 20, svg, colorSilte.at(nspt / 50));
        case 12:
            return patternSilteArenoArgiloso(20, 20, svg, colorSilte.at(nspt / 50));
        case 13:
            return patternSilteArenoso(20, 20, svg, colorSilte.at(nspt / 50));
        case 14:
            return patternSilteArgiloArenoso(20, 20, svg, colorSilte.at(nspt / 50));
        case 15:
            return patternSilteArgiloso(20, 20, svg, colorSilte.at(nspt / 50));
    }
}

export default perfilSondagemSvg;